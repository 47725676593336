import Vue from 'vue'
import VueResource from 'vue-resource'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import Toast from "vue-toastification";

import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n'

import "vue-toastification/dist/index.css";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/css/app.scss'

Vue.use(Toast, {
	
});

Vue.use(VueResource)
Vue.use(Vuelidate)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
});

Vue.config.productionTip = false

new Vue({   
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
